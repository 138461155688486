import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Col, InputNumber, Row, Spin, notification } from "antd";
import Modal from "antd/lib/modal/Modal";
import queryString from "query-string";
import React, { Component } from "react";
import ReactDataSheet from "react-datasheet";
import { connect } from "react-redux";
import { deleteZone, editZone, getMerchantById } from "../util/APIUtils";
import "./ViewZones.css";

const SheetRenderer = (props) => {
  const { as: Tag, headerAs: Header, bodyAs: Body, rowAs: Row, cellAs: Cell, className, columns } = props;
  return (
    <Tag className={className}>
      <Header className="data-header">
        <Row>
          {columns.map((column) => (
            <Cell className="cell" style={{ width: column.width, textAlign: column.textAlign }} key={column.label}>
              {column.label}
            </Cell>
          ))}
        </Row>
      </Header>
      <Body className="data-body">{props.children}</Body>
    </Tag>
  );
};

const RowRenderer = (props) => {
  const { as: Tag, cellAs: Cell, className, row, selected, onSelectChanged } = props;
  return <Tag className={className}>{props.children}</Tag>;
};

const CellRenderer = (props) => {
  const { as: Tag, cell, row, col, columns, attributesRenderer, selected, editing, updated, style, ...rest } = props;
  const attributes = cell.attributes || {};
  attributes.style = { width: columns[col].width, textAlign: columns[col].textAlign, height: 26 };
  if (col === 0) {
    attributes.title = cell.label;
  }

  return (
    <Tag {...rest} {...attributes}>
      {props.children}
    </Tag>
  );
};
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const WindowCard = ({
  addRows,
  title,
  dataSource,
  sheetRenderer,
  rowRenderer,
  cellRenderer,
  onCellsChanged,
  onChangeNumRowsToAdd,
  numRowsToAdd,
}) => {
  return (
    <Card bordered={false} title={title}>
      <Row gutter={16} style={{ marginBottom: 10 }}>
        <Col span={24}>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <InputNumber
              min={0}
              onChange={onChangeNumRowsToAdd}
              value={numRowsToAdd}
              style={{ width: 80, marginRight: 4 }}
            />
            <Button onClick={addRows}>Add rows</Button>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <ReactDataSheet
            data={dataSource}
            className="custom-sheet"
            sheetRenderer={sheetRenderer}
            rowRenderer={rowRenderer}
            cellRenderer={cellRenderer}
            onCellsChanged={onCellsChanged}
            valueRenderer={(cell) => cell.value}
          />
        </Col>
      </Row>
    </Card>
  );
};

export class ViewZones extends Component {
  constructor(props) {
    super(props);

    this.handleCellsChanged = this.handleCellsChanged.bind(this);
    this.sheetRenderer = this.sheetRenderer.bind(this);
    this.rowRenderer = this.rowRenderer.bind(this);
    this.cellRenderer = this.cellRenderer.bind(this);
    this.locationPricingSheetRenderer = this.locationPricingSheetRenderer.bind(this);
    this.locationPricingCellRenderer = this.locationPricingCellRenderer.bind(this);
    this.handleAddRows = this.handleAddRows.bind(this);
    this.handleNumRowsToAdd = this.handleNumRowsToAdd.bind(this);

    this.state = {
      loading: false,
      merchant: null,
      deleteLoadings: [],
      loadingMerchant: false,
      currentSearchParam: null,
      locationPricingGrid: [[{ value: "" }, { value: "" }]],
      showEditLocationPricingModal: [],
      locationPricingColumns: [
        { label: "FSA", width: "50%", textAlign: "right" },
        { label: "Price", width: "50%", textAlign: "right" },
      ],
      loadingLocationPricingChange: false,
      numRowsToAdd: {
        locationPricingGrid: 1,
      },
    };
  }
  componentDidMount() {
    this.setState({ loadingMerchant: true });
    const values = queryString.parse(this.props.location.search);
    if (values.searchParam) this.setState({ currentSearchParam: values.searchParam });
    let promise = getMerchantById(values.merchantId);
    promise
      .then((response) => {
        this.setState({
          loadingMerchant: false,
          merchant: response,
        });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not get merchant",
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingMerchant: false });
      });
  }

  handleAddRows = (grid) => {
    if (!grid || !this.state.numRowsToAdd?.[grid] || !this.state[grid]?.[0]) {
      return;
    }
    const numColumns = this.state[grid][0].length;
    const newGrid = [...this.state[grid]].filter((band) => band[0].value !== "");
    const rowTemplate = [];
    for (let i = 0; i < numColumns; i++) {
      let val = "";
      rowTemplate.push({ value: val });
    }
    for (let i = 0; i < this.state.numRowsToAdd[grid]; i++) {
      const newRow = [...rowTemplate];
      newGrid.push(newRow);
    }
    this.setState({ [grid]: newGrid });
  };

  handleCellsChanged(myGrid, changes, additions, numCols) {
    const grid = this.state[myGrid].map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      grid[row][col] = { ...grid[row][col], value };
    });
    additions &&
      additions.forEach(({ cell, row, col, value }) => {
        if (!grid[row])
          grid[row] = Array.apply(null, Array(numCols)).map(() => {
            return { ["value"]: "" };
          });
        if (grid[row][col]) grid[row][col] = { ["value"]: value };
      });
    this.setState({ [myGrid]: grid });
  }

  handleNumRowsToAdd = (grid, val) => {
    const newRowsData = { ...this.state.numRowsToAdd };
    newRowsData[grid] = val;
    this.setState({ numRowsToAdd: newRowsData });
  };

  handleSaveZonesChanges = (zoneName) => {
    this.setState({ loadingLocationPricingChange: true });
    const pricingRows = [];

    for (const row of this.state.locationPricingGrid) {
      if (!row[0].value?.trim() && !row[1].value?.trim()) {
        continue;
      } else if (!row[0].value?.trim() || !row[1].value?.trim()) {
        const args = {
          message: "Error!",
          description: `Postal "${row[0].value
            ?.trim()
            .toUpperCase()}" with price "${row[1].value?.trim()}" is an invalid row.`,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingLocationPricingChange: false });
        return;
      }
      pricingRows.push({
        postalPrefix: row[0].value?.trim().toUpperCase(),
        price: Number(row[1].value?.trim()),
      });
    }

    if (pricingRows.length == 0) {
      const args = {
        message: "Error!",
        description: `Rows cannot be empty.`,
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      this.setState({ loadingLocationPricingChange: false });
      return;
    }
    const body = {
      zoneName: zoneName,
      pricingRows,
    };

    let promise = editZone(body, this.state.merchant.id);
    promise
      .then(() => {
        const currentZoneIndex = this.state.merchant.zones.findIndex((zone) => zone.name === zoneName);
        let myZones = this.state.merchant.zones;
        myZones[currentZoneIndex].locationPricing.pricingRows = pricingRows;
        this.setState({
          merchant: {
            ...this.state.merchant,
            zones: myZones,
          },
        });

        const args = {
          message: "Success!",
          description: "Postal codes updated.",
          duration: 5,
          type: "success",
        };
        notification.open(args);
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: `${error.message}`,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
      })
      .finally(() => {
        this.setState({ loadingLocationPricingChange: false });
      });
  };

  handleDeleteZone = (zoneName, rowIndex, columnIndex) => {
    const newDeleteLoadings = [...this.state.deleteLoadings];
    newDeleteLoadings[rowIndex] = [];
    newDeleteLoadings[rowIndex][columnIndex] = true;
    this.setState({ deleteLoadings: newDeleteLoadings });

    let promise = deleteZone(this.state.merchant.id, zoneName);
    promise
      .then((response) => {
        const args = {
          message: "Success!",
          description: "Zone Deleted.",
          duration: 3,
          type: "success",
          placement: "topRight",
        };
        notification.open(args);
        const newDeleteLoadings = [...this.state.deleteLoadings];
        newDeleteLoadings[rowIndex] = [];
        newDeleteLoadings[rowIndex][columnIndex] = false;
        this.setState({ deleteLoadings: newDeleteLoadings });

        let newArr = this.state.merchant.zones;
        newArr = newArr.filter((e) => e.name !== zoneName);
        this.setState({
          merchant: {
            ...this.state.merchant,
            zones: newArr,
          },
        });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: error.message,
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        const newDeleteLoadings = [...this.state.deleteLoadings];
        newDeleteLoadings[rowIndex] = [];
        newDeleteLoadings[rowIndex][columnIndex] = false;
        this.setState({ deleteLoadings: newDeleteLoadings });
      });
  };

  setShowLocationPricingModal = (rowIndex, columnIndex, newState, zoneName) => {
    let zoneIndex = this.state.merchant.zones.findIndex((x) => x.name.toUpperCase() === zoneName.toUpperCase());
    const locationPricingRows = this.state.merchant.zones[zoneIndex].locationPricing.pricingRows;

    let newWLocationPricingEntries = locationPricingRows.map((e) => {
      return [{ value: e.postalPrefix }, { value: e.price.toString() }];
    });

    const newShowLocationPricingModal = [...this.state.showEditLocationPricingModal];
    newShowLocationPricingModal[rowIndex] = [];
    newShowLocationPricingModal[rowIndex][columnIndex] = newState;

    this.setState({
      locationPricingGrid: newWLocationPricingEntries,
      showEditLocationPricingModal: newShowLocationPricingModal,
    });
  };

  clickBackButton = () => {
    let link = document.createElement("a");
    link.href =
      `/merchants/viewMerchants` +
      (this.state.currentSearchParam ? `?searchParam=${this.state.currentSearchParam}` : "");
    document.body.appendChild(link);
    link.click();
  };

  locationPricingSheetRenderer(props) {
    const { locationPricingColumns } = this.state;
    return (
      <SheetRenderer
        columns={locationPricingColumns}
        as="table"
        headerAs="thead"
        bodyAs="tbody"
        rowAs="tr"
        cellAs="th"
        {...props}
      />
    );
  }

  rowRenderer(props) {
    return <RowRenderer as="tr" cellAs="td" className="data-row" {...props} />;
  }

  cellRenderer(props) {
    return <CellRenderer as="td" columns={this.state.deliveryWindowColumns} {...props} style={{ float: "left" }} />;
  }

  locationPricingCellRenderer(props) {
    return <CellRenderer as="td" columns={this.state.locationPricingColumns} {...props} style={{ float: "left" }} />;
  }

  sheetRenderer(props) {
    const { deliveryWindowColumns } = this.state;
    return (
      <SheetRenderer
        columns={deliveryWindowColumns}
        as="table"
        headerAs="thead"
        bodyAs="tbody"
        rowAs="tr"
        cellAs="th"
        {...props}
      />
    );
  }

  render() {
    const mapCards = (arr) => {
      const copied = Array.from(arr);
      const rows = copied.reduceRight((r, i, _, s) => (r.push(s.splice(0, 3)), r), []);
      return (
        <>
          {rows.map((row, i) => (
            <Row key={i} gutter={16} style={{ marginBottom: 10 }}>
              {row.map((cell, j) =>
                cell.addZone == null ? (
                  <Col key={j} span={8}>
                    <Card
                      bordered={false}
                      title={
                        <>
                          {cell.name}
                          <Button
                            style={{ float: "right" }}
                            type="danger"
                            icon={<DeleteOutlined />}
                            onClick={() => this.handleDeleteZone(cell.name, i, j)}
                            loading={this.state.deleteLoadings[i] ? this.state.deleteLoadings[i][j] : false}
                          />
                        </>
                      }
                    >
                      <Row style={{ marginBottom: 5 }}>Facility: {cell.facility}</Row>
                      <Row style={{ marginBottom: 5 }}>Timezone: {cell.timezone}</Row>
                      <Row>
                        <Button
                          onClick={() => {
                            this.setShowLocationPricingModal(i, j, true, cell.name);
                          }}
                          disabled={
                            this.state.merchant.zones == null &&
                            this.state.merchant.zones == null &&
                            this.state.merchant.zones.locationPricing == null
                          }
                        >
                          View Location Pricing
                        </Button>
                      </Row>
                    </Card>

                    <Modal
                      title={<>Change Location Pricing for: {cell.name}</>}
                      visible={
                        this.state.showEditLocationPricingModal[i]
                          ? this.state.showEditLocationPricingModal[i][j]
                          : false
                      }
                      cancelButtonProps={{ style: { display: "none" } }}
                      okButtonProps={{ style: { display: "none" } }}
                      closable={true}
                      style={{ minWidth: "70%" }}
                      onCancel={() => this.setShowLocationPricingModal(i, j, false, cell.name)}
                    >
                      <Spin spinning={!!this.state.loadingLocationPricingChange}>
                        <Row>
                          <Col span={24}>
                            <WindowCard
                              dataSource={this.state.locationPricingGrid}
                              className="custom-sheet"
                              sheetRenderer={this.locationPricingSheetRenderer}
                              rowRenderer={this.rowRenderer}
                              cellRenderer={this.locationPricingCellRenderer}
                              onCellsChanged={(changes, additions) => {
                                this.handleCellsChanged("locationPricingGrid", changes, additions, 2);
                              }}
                              addRows={() => this.handleAddRows("locationPricingGrid")}
                              onChangeNumRowsToAdd={(num) => this.handleNumRowsToAdd("locationPricingGrid", num)}
                              numRowsToAdd={this.state.numRowsToAdd["locationPricingGrid"]}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Button
                              loading={this.state.loadingLocationPricingChange}
                              type="primary"
                              onClick={() => this.handleSaveZonesChanges(cell.name)}
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Spin>
                    </Modal>
                  </Col>
                ) : (
                  <Col key={j} span={8}>
                    <Card bordered={false} title={<Row style={{ marginBottom: 6 }}>Add Zone</Row>}>
                      <Row style={{ marginBottom: 5 }}>
                        <Button type="primary">
                          <a
                            href={
                              `/merchants/${this.state.merchant.name}/addZones?merchantId=${this.state.merchant.id}` +
                              (this.state.currentSearchParam ? `&searchParam=${this.state.currentSearchParam}` : "")
                            }
                          >
                            Add Zone
                          </a>
                        </Button>
                      </Row>
                      <Row>&nbsp;</Row>
                    </Card>
                  </Col>
                ),
              )}
            </Row>
          ))}
        </>
      );
    };
    return this.state.merchant == null || this.state.loadingMerchant ? (
      <div className={"viewZones-container"}>
        <Spin />
      </div>
    ) : (
      <Spin spinning={this.state.loadingLocationPricingChange}>
        <div className={"viewZones-container"}>
          <h1>
            <Button type="primary" onClick={this.clickBackButton}>
              <ArrowLeftOutlined />
            </Button>{" "}
            Merchants/{this.state.merchant.name}/Zones
          </h1>
          <div className="zone-cards-container">
            {this.state.merchant.zones != null
              ? mapCards(this.state.merchant.zones.concat([{ addZone: true }]))
              : mapCards([{ addZone: true }])}
          </div>
        </div>
      </Spin>
    );
  }
}

ViewZones.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ViewZones);
