import axios from "axios";
import { updateQueryStringParameter } from "../util/Helpers";
const BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL; // "https://admin-api.boxknight.com";
const DRIVERS_BASE_URL = "https://6vvs8qa3g6.execute-api.us-east-1.amazonaws.com/prod";

const request = (options) => {
  let headers = {
    "Content-Type": "application/json",
  };

  addHeaderIfLocal(headers);
  if (options.queryParams) {
    options.url += (options.url.indexOf("?") === -1 ? "?" : "&") + queryParams(options.queryParams);
    delete options.queryParams;
  }

  if (localStorage.getItem("token")) {
    options.headers.Authorization = localStorage.getItem("token");
  }
  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);
  return fetch(options.url, options).then((response) => {
    if (response.headers.get("content-type").indexOf("application/pdf") > -1) {
      return response.text();
    }
    return response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    });
  });
};

const fileRequest = (options) => {
  let headers = {
    "Content-Type": "application/json",
  };

  addHeaderIfLocal(headers);

  if (options.queryParams) {
    options.url += (options.url.indexOf("?") === -1 ? "?" : "&") + queryParams(options.queryParams);
    delete options.queryParams;
  }

  if (localStorage.getItem("token")) {
    headers.Authorization = localStorage.getItem("token");
  }
  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);
  return fetch(options.url, options).then((response) => {
    if (!response.ok) {
      return Promise.reject(response.arrayBuffer());
    }
    return response.arrayBuffer();
  });
};

const queryParams = (params) => {
  return Object.keys(params)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
    .join("&");
};

export function login(loginData) {
  return request({
    url: `${BASE_URL}/user/login`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(loginData),
  });
}

export function register(registerData) {
  return request({
    url: `${BASE_URL}/user/register`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(registerData),
  });
}

export function getMerchants(offset, limit) {
  return request({
    url: `${BASE_URL}/merchants?offset=${offset}&limit=${limit}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getMerchantsNameSearch(name) {
  return request({
    url: `${BASE_URL}/merchants/nameSearch?name=${name}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getMerchantNames() {
  return request({
    url: `${BASE_URL}/merchants/names`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getWorkerById(workerId) {
  return request({
    url: `${BASE_URL}/onfleet/worker?workerId=${workerId}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getTaskById(taskId) {
  return request({
    url: `${BASE_URL}/onfleet/task?id=${taskId}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function markDriversInReportAsPaid(reportId, body) {
  return request({
    url: `${DRIVERS_BASE_URL}/drivers/payroll/markAsPaid/${reportId}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function markDriversInReportAsUnpaid(reportId, body) {
  return request({
    url: `${DRIVERS_BASE_URL}/drivers/payroll/markAsUnpaid/${reportId}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function addZone(zoneData, merchantId) {
  return request({
    url: `${BASE_URL}/merchants/${merchantId}/zones`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(zoneData),
  });
}

export function editZone(zoneData, merchantId) {
  return request({
    url: `${BASE_URL}/merchants/${merchantId}/zones/update`,
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(zoneData),
  });
}

export function deleteZone(merchantId, zoneName) {
  return request({
    url: `${BASE_URL}/merchants/${merchantId}/zones?zoneName=${zoneName}`,
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getMerchantById(merchantId) {
  return request({
    url: `${BASE_URL}/merchants/${merchantId}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getDriverById(id) {
  return request({
    url: `${DRIVERS_BASE_URL}/drivers/byId/${id}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}
export function getDriverProfileEventsByDriverId(driverId) {
  return request({
    url: `${DRIVERS_BASE_URL}/drivers/driverProfileEvents/${driverId}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getDriverInvoicesByDriverId(driverId) {
  return request({
    url: `${DRIVERS_BASE_URL}/drivers/driverInvoices/${driverId}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}
export function getDriverInvoicePDFByInvoiceKey(invoiceKey) {
  return request({
    url: `${DRIVERS_BASE_URL}/drivers/driverInvoices/pdf/${invoiceKey}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getDriverTicketsByDriverId(driverId) {
  return request({
    url: `${DRIVERS_BASE_URL}/drivers/driverTickets/${driverId}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getAllShippingRules(merchantId) {
  return request({
    url: `${BASE_URL}/merchants/${merchantId}/shippingRules`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function addShppingRule(data, merchantId) {
  return request({
    url: `${BASE_URL}/merchants/${merchantId}/shippingRules`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export function deleteShippingRule(merchantId, originZone, destinationZone) {
  return request({
    url: `${BASE_URL}/merchants/${merchantId}/shippingRules?originZone=${originZone}&destinationZone=${destinationZone}`,
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function editShippingRule(data, merchantId, operation) {
  return request({
    url: `${BASE_URL}/merchants/${merchantId}/shippingRules/${operation}`,
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export function getTeams() {
  return request({
    url: `${BASE_URL}/teams`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getDrivers() {
  // alert(`${DRIVERS_BASE_URL}/drivers`)
  return request({
    url: `${DRIVERS_BASE_URL}/drivers`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getDriverApplicantByEmail(email) {
  return request({
    url: `${DRIVERS_BASE_URL}/driverApplicants/${encodeURIComponent(email)}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getDriverApplicantVehiclePhoto(key) {
  return request({
    url: `${DRIVERS_BASE_URL}/driverApplicants/vehiclePhoto/${encodeURIComponent(key)}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getDriverApplicantDriversLicense(key) {
  return request({
    url: `${DRIVERS_BASE_URL}/driverApplicants/driversLicensePhoto/${encodeURIComponent(key)}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function editDriverApplicant(data, email) {
  return request({
    url: `${DRIVERS_BASE_URL}/driverApplicants/${email}`,
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export function getAllOnfleetDrivers() {
  return request({
    url: `${BASE_URL}/onfleet/drivers`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getSchedule(city, from, to) {
  return axios.get(`https://api.boxknight.com/v1/schedulerexport?from=${from}&to=${to}&city=${city}`);
}

export function getPayrollReportById(id) {
  return request({
    url: `${DRIVERS_BASE_URL}/drivers/payroll/${id}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getDriverSchedule(id, from, to) {
  return request({
    url: `${DRIVERS_BASE_URL}/driverSchedule?workerId=${id}&from=${from}&to=${to}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getDriverAnalytics(id, from, to) {
  return request({
    url: `${DRIVERS_BASE_URL}/driverAnalytics?id=${id}&from=${from}&to=${to}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function calculateDriverPay(driverData) {
  return request({
    url: `${DRIVERS_BASE_URL}/driverPay`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(driverData),
  });
}

export function generateInvoice(driverData) {
  return request({
    url: `${DRIVERS_BASE_URL}/generateInvoice`,
    method: "POST",
    headers: {
      Accept: "application/pdf",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(driverData),
  });
}

export function createPayrollReport(data) {
  return request({
    url: `${DRIVERS_BASE_URL}/drivers/payroll`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export function getMultipleDrivers(idList) {
  return request({
    url: `${DRIVERS_BASE_URL}/drivers/list?workerIds=${idList}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getRates(merchantId, data) {
  return request({
    url: `${BASE_URL}/rates/${merchantId}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export function getWindows(merchantId, myDateTime, postalCode, originPostalCode) {
  return request({
    url: `${BASE_URL}/rates/windows/${merchantId}?dateTime=${myDateTime}&postalCode=${postalCode}&originPostalCode=${originPostalCode}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getOrderById(id) {
  return request({
    url: `${BASE_URL}/orders/getOrders/${id}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function createBatch(body) {
  return request({
    url: `${BASE_URL}/batch`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function getBatchById(batchId) {
  return request({
    url: `${BASE_URL}/batch/${batchId}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function assignTasksAtIndex(workerId, body) {
  return request({
    url: `${BASE_URL}/onfleet/workers/${workerId}`,
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function getUsers() {
  return request({
    url: `${BASE_URL}/user/manageUsers`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function deleteUser(email) {
  return request({
    url: `${BASE_URL}/user/manageUsers/${email}`,
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function createUser(body) {
  return request({
    url: `${BASE_URL}/user/manageUsers`,
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function createServiceUser(merchantId) {
  return request({
    url: `${BASE_URL}/merchants/${merchantId}/user`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ group: "service" }),
  });
}

export function editUser(body) {
  return request({
    url: `${BASE_URL}/user/manageUsers`,
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function updateOrder(orderId, body) {
  return request({
    url: `${BASE_URL}/orders/updateOrder/${orderId}`,
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function scanApp(baseId, packageId, location) {
  return request({
    url: `${BASE_URL}/orders/scanApp?orderId=${baseId}&exp=true&packageId=${packageId}&location=${location}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function updateAddress(orderId, body, addressToEdit) {
  return request({
    url: `${BASE_URL}/orders/${addressToEdit}/${orderId}`,
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function getLabelByOrderId(orderId) {
  return request({
    url: `${BASE_URL}/labels/${orderId}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function searchOrders(filters) {
  const body = {};
  if (filters.id != null && filters.id.length > 0) {
    body.id = filters.id;
  }
  if (filters.refNumber != null && filters.refNumber.length > 0) {
    body.refNumber = filters.refNumber;
  }
  if (filters.merchantId != null && filters.merchantId.length > 0) {
    body.merchantId = filters.merchantId;
  }
  if (filters.orderStatus != null && filters.orderStatus.length > 0) {
    body.orderStatus = filters.orderStatus;
  }
  if (filters.deliveryType != null && filters.deliveryType.length > 0) {
    body.deliveryType = filters.deliveryType;
  }
  if (filters.createdAtStart != null) {
    body.createdAtStart = filters.createdAtStart;
  }
  if (filters.createdAtEnd != null) {
    body.createdAtEnd = filters.createdAtEnd;
  }
  if (filters.completeAfter != null) {
    body.completeAfter = filters.completeAfter;
  }
  if (filters.completeBefore != null) {
    body.completeBefore = filters.completeBefore;
  }
  if (filters.lastEvaluatedKey != null) {
    body.lastEvaluatedKey = filters.lastEvaluatedKey;
  }
  if (filters.createdAt != null) {
    body.createdAt = filters.createdAt;
  }
  if (filters.limit != null) {
    body.limit = filters.limit;
  }

  return request({
    url: `${BASE_URL}/orders`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function editDriver(driverData, id) {
  return request({
    url: `${DRIVERS_BASE_URL}/drivers/${id}`,
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(driverData),
  });
}

export function editMerchant(merchantData, id) {
  return request({
    url: `${BASE_URL}/merchants/${id}`,
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(merchantData),
  });
}

export function addMerchantGoboltOrgId(id) {
  return request({
    url: `${BASE_URL}/merchants/${id}/gobolt/organization`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
}

export function addMerchantGoboltPickupCustomerId(id) {
  return request({
    url: `${BASE_URL}/merchants/${id}/gobolt/pickupCustomer`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
}

export function patchWindows(windowData, orderId) {
  return request({
    url: `${BASE_URL}/orders/windows/${orderId}`,
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(windowData),
  });
}

export function setReady(orderId) {
  return request({
    url: `${BASE_URL}/orders/${orderId}/pickupready`,
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function reviveOrder(orderId) {
  return request({
    url: `${BASE_URL}/orders/${orderId}/revive`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function completeTask(orderId, completeTaskData) {
  return request({
    url: `${BASE_URL}/orders/completeTask/${orderId}`,
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(completeTaskData),
  });
}

export function addTask(orderId) {
  return request({
    url: `${BASE_URL}/orders/${orderId}/addTask`,
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

// used for testing locally
const addHeaderIfLocal = (headers) => {
  if (BASE_URL.includes("localhost") && process.env.REACT_APP_LOCAL_MERCHANT_ID) {
    headers["x-aws-sqsd-attr-merchant-id"] = process.env.REACT_APP_LOCAL_MERCHANT_ID;
  }
};

export function updateBatchOrderTasks(body) {
  return request({
    url: `${BASE_URL}/batch/orders/update`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function retryGoboltOrderCreation(orderId) {
  return request({
    url: `${BASE_URL}/orders/retry/${orderId}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function bulkUpdateOrdersByOperation(operation, body) {
  return request({
    url: `${BASE_URL}/orders/admin/bulk/${operation}`,
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function archiveMerchantById(merchantId) {
  return request({
    url: `${BASE_URL}/merchants/${merchantId}/archive`,
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function addMerchant(merchantData) {
  return request({
    url: `${BASE_URL}/merchants/create`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(merchantData),
  });
}

export function getUsersByMerchantId(merchantId) {
  return request({
    url: `${BASE_URL}/merchants/${merchantId}/users`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function deleteUserByUsername(merchantId, username) {
  return request({
    url: `${BASE_URL}/merchants/${merchantId}/users/${username}`,
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function editUserByUsername(username, body) {
  return request({
    url: `${BASE_URL}/user/${username}`,
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function createMerchantUser(merchantId, body) {
  return request({
    url: `${BASE_URL}/merchants/${merchantId}/user`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...body, group: "merchant_admin" }),
  });
}

export function getUserByUsername(username) {
  return request({
    url: `${BASE_URL}/user/${username}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getFeatureFlag(name) {
  return request({
    url: `${BASE_URL}/orders/admin/featureFlags/${name}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}
